import React, { useEffect, useState } from "react";
import { CustomerStatus } from "@models/Customer";
import { DivisionStatus } from "@models/DivisionStatus";
import styled from "styled-components";
import { colors } from "@theme/theming";
import { ActionButton, ButtonsContainer, QueryError } from "@components";
import { useAsyncCommand, useDialog, useServices } from "@hooks";
import { Alert, Spinner } from "react-bootstrap";

type StatusToggleProps = { targetId: string; onSuccess: () => void } & (
	| { target: "customer"; curStatus: CustomerStatus }
	| { target: "division"; curStatus: DivisionStatus }
);

type StatusProps = {
	status: CustomerStatus | DivisionStatus;
};

const StatusMap = {
	customer: CustomerStatus,
	division: DivisionStatus,
};

const getStatusColor = (status: StatusProps["status"]): string => {
	switch (status) {
		case CustomerStatus.Trial:
		case DivisionStatus.Trial:
			return colors.info.i300;
		case CustomerStatus.Active:
		case DivisionStatus.Active:
			return colors.success.s300;
		case CustomerStatus.Deactivated:
		case DivisionStatus.Deactivated:
			return colors.gray.g300;
		case CustomerStatus.Archived:
		case DivisionStatus.Archived:
			return colors.black;
	}
};

export const getStatusText = (status: StatusProps["status"]): string => {
	switch (status) {
		case CustomerStatus.Trial:
		case DivisionStatus.Trial:
			return "Trial";
		case CustomerStatus.Active:
		case DivisionStatus.Active:
			return "Aktiv";
		case CustomerStatus.Deactivated:
		case DivisionStatus.Deactivated:
			return "Deaktiviert";
		case CustomerStatus.Archived:
		case DivisionStatus.Archived:
			return "Archiviert";
	}
};

export const getStatusAction = (
	status: StatusProps["status"]
): { doing: string; do: string } => {
	switch (status) {
		case CustomerStatus.Trial:
		case DivisionStatus.Trial:
			throw new Error(`Unexpected status: ${status}`);
		case CustomerStatus.Active:
		case DivisionStatus.Active:
			return { doing: "aktiviere", do: "aktivieren" };
		case CustomerStatus.Deactivated:
		case DivisionStatus.Deactivated:
			return { doing: "deaktiviere", do: "deaktivieren" };
		case CustomerStatus.Archived:
		case DivisionStatus.Archived:
			return { doing: "archiviere", do: "archivieren" };
	}
};

export const StatusToggle = (props: StatusToggleProps) => {
	const { target, targetId, curStatus, onSuccess } = props;
	const targetStatus = StatusMap[target];

	const { showConfirmDialog } = useDialog();
	const [submittedStatus, setSubmittedStatus] = useState<
		CustomerStatus | DivisionStatus | null
	>(null);

	// reset isLoading only after onSuccess updates the entity
	useEffect(() => setSubmittedStatus(null), [curStatus]);

	const { customerService, divisionService } = useServices();
	const [updateTargetStatus, state] = useAsyncCommand(async (status) => {
		setSubmittedStatus(status);

		switch (target) {
			case "customer":
				await customerService.updateCustomerStatus(
					targetId,
					status as CustomerStatus
				);
				break;
			case "division":
				await divisionService.updateDivisionStatus(
					targetId,
					status as DivisionStatus
				);
				break;
		}

		onSuccess();
	});

	const handleStatusButtonPress = (
		status: StatusProps["status"],
		label: string
	) => {
		showConfirmDialog({
			onConfirm: () => updateTargetStatus(status),
			title: label.replace("Kunde", "Kunden"),
			text: (
				<>
					Sind Sie sicher, dass Sie den {label.replace("Kunde", "Kunden")}{" "}
					wollen?
					{target === "division" && status === DivisionStatus.Deactivated ? (
						<DivisionDeactivationWarning />
					) : (
						<></>
					)}
				</>
			),
		});
	};

	const targetDE = target === "customer" ? "Kunde" : "Standort";

	const StatusButton = ({ status }: StatusProps) => {
		const submittingThisStatus = status === submittedStatus;
		const action = getStatusAction(status);
		const statusLabel = submittingThisStatus
			? `${action.doing} ${targetDE}`
			: `${targetDE} ${action.do}`;

		return (
			<StyledActionButton
				key={`${targetId}-${status}`}
				status={status}
				state={submittingThisStatus ? state : undefined}
				variant="secondary"
				onClick={() =>
					handleStatusButtonPress(status, `${targetDE} ${action.do}`)
				}
			>
				{submittingThisStatus && <StyledSpinner size="sm" animation="border" />}
				{statusLabel}
			</StyledActionButton>
		);
	};

	return (
		<StatusToggleContainer>
			{[
				targetStatus.Trial,
				targetStatus.Deactivated,
				targetStatus.Archived,
			].includes(curStatus) && <StatusButton status={targetStatus.Active} />}

			{curStatus === targetStatus.Active && (
				<StatusButton status={targetStatus.Deactivated} />
			)}

			{curStatus === targetStatus.Deactivated && (
				<StatusButton status={targetStatus.Archived} />
			)}
			<QueryError queryState={state} />
		</StatusToggleContainer>
	);
};

const DivisionDeactivationWarning = (): JSX.Element => (
	<>
		<Alert variant="primary" style={{ marginBottom: 0, marginTop: 19 }}>
			<b>Achtung:</b> Sie sind dabei, einen Standort zu deaktivieren.
			<br />
			Bitte stellen Sie sicher, dass Sie alle relevanten Informationen und Daten
			gesichert haben, bevor Sie den Vorgang fortsetzen.
		</Alert>
		<br />
		Nach der Deaktivierung werden die folgenden Maßnahmen durchgeführt:
		<ol style={{ marginTop: 5 }}>
			<li>
				Alle nicht beendeten Benutzer werden zum Ende des vorherigen Monats
				beendet.
			</li>
			<li>
				Alle aktiven Modul-Einstellungen und Dauerbelege werden zum Ende des
				vorherigen Monats beendet.
			</li>
			<li>
				Alle zukünftigen Modul-Einstellungen und Dauerbelege werden gelöscht.
			</li>
			<li>
				Alle Preiseinstellungen werden zum Ende des vorherigen Monats beendet.
			</li>
			<li>Alle Benutzer, die in der Zukunft beginnen, werden gelöscht.</li>
			<li>Die JobRad Integration wird gelöscht.</li>
		</ol>
		Nach der Deaktivierung des Standorts kann der Kunde{" "}
		<b>keine Änderungen mehr vornehmen</b> und{" "}
		<b>keine neuen Einträge erstellen</b>, weder über das Portal noch über die
		öffentliche API, einschließlich:
		<ul style={{ marginTop: 5 }}>
			<li>Benutzer</li>
			<li>Modul-Einstellungen</li>
			<li>Report-Einstellungen</li>
			<li>Neue Integrationen</li>
		</ul>
		<Alert variant="danger" style={{ marginBottom: 0 }}>
			<b>Wichtiger Hinweis:</b> Die Deaktivierung ist ein irreversibler Vorgang.
			Alle oben genannten Aktionen können <strong>nicht</strong> rückgängig
			gemacht werden.
		</Alert>
	</>
);

const StatusToggleContainer = styled(ButtonsContainer)`
	width: min-content;
`;

const StyledActionButton = styled(ActionButton)<{
	status: CustomerStatus | DivisionStatus;
}>`
	width: max-content;
	color: ${({ status }) => getStatusColor(status)};
	background: ${colors.white};
	border-color: ${({ status }) => getStatusColor(status)};

	&:hover {
		color: ${colors.white};
		background-color: ${({ status }) => getStatusColor(status)};
		border-color: ${({ status }) => getStatusColor(status)};
	}
`;

const StyledSpinner = styled(Spinner)`
	height: 1em;
	width: 1em;
	align-self: center;
	margin-top: 2px;
`;

export const StatusSpan = ({ status }: StatusProps) => (
	<StatusSpanContainer status={status}>
		{getStatusText(status)}
	</StatusSpanContainer>
);

const StatusSpanContainer = styled.span<{
	status: CustomerStatus | DivisionStatus;
}>`
	display: flex;
	gap: 1ch;
	width: min-content;
	font-size: 0.8rem;
	padding: 5px;
	font-weight: initial;
	background: ${colors.white};
	border: 1px solid ${colors.gray.g300};
	border-radius: 5px;
	color: ${({ status }) => getStatusColor(status)};
	border-color: ${({ status }) => getStatusColor(status)};
	text-transform: uppercase;
	min-height: 2rem;
`;

import React from "react";
import styled from "styled-components";
import { Toast } from "react-bootstrap";

type Props = {
	header?: string;
	message: string;
	isVisible: boolean;
	onClose?: () => void;
	delay?: number;
};

export default function ToastInfo(props: Props) {
	return (
		<StyledToast
			onClose={props?.onClose}
			show={props.isVisible}
			delay={4000}
			autohide
		>
			<Toast.Header>
				<strong className="mr-auto">{props.header ?? "Warnung"}</strong>
				<small>gerade jetzt</small>
			</Toast.Header>
			<Toast.Body>{props.message}</Toast.Body>
		</StyledToast>
	);
}

const StyledToast = styled(Toast)`
	position: fixed;
	top: 2vw;
	right: 16px;
	z-index: 9999;
	background: white;
`;
